import { getLocalizedValue } from '@/utils/i18n_client'
import { PoolEventResponse } from '@/utils/pool_types'
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Box,
    useTheme,
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import { CSSProperties, FC } from 'react'
import NextLink from 'next/link'
import { AnalyzedPoolEvent } from '@/utils/pools'
import { PoolEventState } from './PoolEventState'
import { getPoolEventSportIcon } from './PoolEventChip'

export const PoolEventCarouselItem: FC<{
    poolEvent: PoolEventResponse
    analysis: AnalyzedPoolEvent
}> = ({ poolEvent, analysis }) => {
    const { i18n } = useTranslation('pools')
    const theme = useTheme()

    let style: CSSProperties = { borderTop: '10px solid #eaeaea' }

    let cardMedia = poolEvent.icon ? (
        <CardMedia
            component="img"
            height={125}
            src={`/images/pools/${poolEvent.icon}.png`}
            alt={getLocalizedValue(poolEvent.name, i18n.language)}
            sx={{
                padding: '1em',
                objectFit: 'contain',
            }}
            title={getLocalizedValue(poolEvent.name, i18n.language)}
        />
    ) : (
        getPoolEventSportIcon(poolEvent.sport, {
            style: { marginTop: '25px' },
            fontSize: 'large',
        })
    )

    if (poolEvent.id === 'gold-cup-w-2024') {
        style = {
            borderTop: '10px solid #503180',
        }
        cardMedia = (
            <CardMedia
                component="img"
                height={125}
                src={`/images/pools/WGC_Logo_PRIMARY_DARK.png`}
                alt={getLocalizedValue(poolEvent.name, i18n.language)}
                sx={{
                    padding: '1em',
                    objectFit: 'contain',
                    backgroundColor: '#291E2F',
                }}
                title={getLocalizedValue(poolEvent.name, i18n.language)}
            />
        )
    } else {
        style = {
            borderTop: `10px solid ${
                poolEvent.headerBackgroundColor ?? theme.palette.primary.main
            }`,
        }
    }

    return (
        <Card key={poolEvent.id} sx={{ maxWidth: 350, flex: 1 }} style={style}>
            <NextLink href={`/pools/${poolEvent.id}/landing`} passHref>
                <CardActionArea LinkComponent="a">
                    {cardMedia}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {getLocalizedValue(poolEvent.name, i18n.language)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {getLocalizedValue(
                                poolEvent.description,
                                i18n.language
                            )}
                        </Typography>
                        <Box>
                            <PoolEventState
                                poolEvent={poolEvent}
                                analysis={analysis}
                                options={{ hidePicksNotAvailableWarning: true }}
                            />
                        </Box>
                    </CardContent>
                </CardActionArea>
            </NextLink>
        </Card>
    )
}
