import { getLocalizedValue } from '@/utils/i18n_client'
import { PoolEventResponse, PoolEventSports } from '@/utils/pool_types'
import {
    Chip,
    SvgIconTypeMap,
    SxProps,
    Theme,
    alpha,
    useTheme,
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import SportsHockeyIcon from '@mui/icons-material/SportsHockey'
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball'
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball'
import SportsFootballIcon from '@mui/icons-material/SportsFootball'
import LockIcon from '@mui/icons-material/Lock'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'

export function getPoolEventSportIcon(
    sport: PoolEventSports,
    props?: DefaultComponentProps<SvgIconTypeMap>
): JSX.Element {
    switch (sport) {
        case 'baseball':
            return <SportsBaseballIcon {...props} />
        case 'basketball':
            return <SportsBasketballIcon {...props} />
        case 'football':
            return <SportsFootballIcon {...props} />
        case 'hockey':
            return <SportsHockeyIcon {...props} />
        default:
            return <SportsSoccerIcon {...props} />
    }
}

export const PoolEventChip: FC<{
    poolEvent: PoolEventResponse
    inTheme?: boolean
}> = ({ poolEvent, inTheme = true }) => {
    const { i18n } = useTranslation('pools')
    const theme = useTheme()

    const sxProps: SxProps<Theme> | undefined = inTheme
        ? {
              color: theme.palette.common.poolEventHeaderLogo,
              backgroundColor: alpha(theme.palette.text.poolEventHeader, 0.2),
          }
        : undefined

    return (
        <Chip
            icon={getPoolEventSportIcon(poolEvent.sport, {
                style: { color: theme.palette.common.poolEventHeaderLogo },
            })}
            component="a"
            clickable
            variant="filled"
            sx={sxProps}
            href={`${process.env.NEXT_PUBLIC_BASE_URL}/pools/${poolEvent.id}`}
            label={
                <>
                    {getLocalizedValue(poolEvent.name, i18n.language)}
                    {poolEvent.private && (
                        <>
                            &nbsp;
                            <LockIcon
                                fontSize="small"
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        </>
                    )}
                </>
            }
        />
    )
}
